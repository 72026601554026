import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CInputBtnInit,
  CBtnList02,
  CHotelMedia,
  CSearchUtils,
  CSearchUtilsItem,
} from '../../components/_index';
import { recruitArea } from '../../utils/recruit';
import { setLang } from '../../utils/set-lang';
import { hotelDatas, areas, tags } from '../../utils/hotels';
import { log } from 'console';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  const areaSet = CInputBtnInit(areas.map((item: any) => item.value));
  const areaData = areas.map((item: any) => {
    return {
      value: item.value,
      label: item.label,
      onChange: areaSet.check,
    };
  });

  const tagSet = CInputBtnInit(
    tags.map((item: any) => item.value),
    [],
    false
  );
  const tagData = tags.map((item: any) => {
    return {
      value: item.value,
      label: item.label,
      onChange: tagSet.check,
    };
  });

  const filterData = hotelDatas
    .filter((item) => {
      let test = false;
      for (let i = 0; item.tag.length > i; i++) {
        if (areaSet.checked.includes(item.tag[i])) {
          test = true;
          break;
        }
      }
      return test;
    })
    .filter((item) => {
      let test = false;
      if (tagSet.checked.length) {
        let checkCount = 0;
        for (let i = 0; item.tag.length > i; i++) {
          if (tagSet.checked.includes(item.tag[i])) {
            checkCount++;
            if (checkCount === tagSet.checked.length) {
              test = true;
              break;
            }
          }
        }
      } else {
        test = true;
      }
      return test;
    });

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            en: 'SEARCH BY EXPERIENCE',
            ja: '過ごし方から探す',
          },
          img: {
            src: '/assets/images/experience/kv.png',
          },
          imgSp: {
            src: '/assets/images/experience/kv__sp.png',
          },
        }}
      />
      <CBreadCrumb
        data={{
          parent: [],
          current: {
            label: '過ごし方から探す',
          },
        }}
      />
      <div className="l_sect03 u_bgGray">
        <LWrap>
          <CSearchUtils title="検索条件">
            <CSearchUtilsItem
              title="エリア"
              data={areaData}
              type="checkbox"
              checkArray={areaSet.checked}
              name="area"
              all={{
                onChange: areaSet.allCheck,
              }}
            />
            <CSearchUtilsItem
              title="タグ"
              data={tagData}
              type="checkbox"
              checkArray={tagSet.checked}
              name="tag"
            />
          </CSearchUtils>
          {filterData.length ? (
            <CHotelMedia searchCategory="experience" data={filterData} />
          ) : (
            <p className="u_tac">一致する検索結果がありません。</p>
          )}
        </LWrap>
      </div>
      <div className="l_sect">
        <LWrap>
          <CBtnList02
            data={[
              {
                label: {
                  ja: 'エリアから探す',
                  en: 'SEARCH BY DESTINATION',
                },
                link: {
                  href: '/location/',
                },
              },
              {
                label: {
                  ja: 'ブランドから探す',
                  en: 'SEARCH BY BRAND',
                },
                link: {
                  href: '/brand/',
                },
              },
            ]}
          />
        </LWrap>
      </div>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
